import React from 'react'

interface Props {
  title: string
  url: string
  feature_image?: any
  publishing_date: string
}

const StoryList: React.FC<Props> = ({
  title,
  url,
  feature_image,
  publishing_date,
}) => {
  return (
    <a href={url}>
      <div className="story">
        <div className="story-preview">
          {feature_image ? (
            <img
              loading="lazy"
              src={feature_image}
              alt={title}
              width="293"
              height="235"
            />
          ) : (
            <span className="no-preview-image">Mega</span>
          )}
        </div>
        <div className="story-title-wrap">
          <span className="story-title">{title}</span>
          <span className="story-date">{publishing_date}</span>
        </div>
      </div>
    </a>
  )
}

export default StoryList
