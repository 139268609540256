import React from 'react'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { LATEST_RC } from '../../components/download-links'
import { RUMMY, RUMMY_STORIES } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import Stories from '../../components/Stories/stories'
import { rummyOneLinksRummyPage } from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Rummy',
    url: RUMMY,
  },
  {
    title: 'Web Stories',
    url: RUMMY_STORIES,
  },
]
const RummyWebStories: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={LATEST_RC}
      showRummyForm
      rummyOneLink={rummyOneLinksRummyPage}
    >
      <SEO
        title="Rummy Web Stories: Interactive And Informative | Mega"
        description="Explore Rummy stories and learn new strategies to improve your game on our Rummy web stories page. Discover the world of Rummy like never before!"
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Stories
        title="Rummy - Web Stories"
        prefix={`${RUMMY_STORIES}` || '/'}
        storyType="rummy"
        displayFilters={false}
      />
    </Layout>
  )
}

export default RummyWebStories
