import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { generateBlogItemsSchema } from '../../lib/generate-schema'
import StoryList from './storylist'
import './stories.scss'

interface Props {
  title: string | React.ReactNode
  prefix: string
  storyType: string
  displayFilters?: boolean
}

const Stories: React.FC<Props> = ({
  title,
  prefix,
  storyType,
  displayFilters = true,
}) => {
  const [webStories, setWebStories] = useState<any | null>([])
  const [allWebStories, setAllWebStories] = useState<any | null>([])
  const [displayTags, setDisplayTags] = useState<any | null>([])
  const [allStoryCount, setAllStoryCount] = useState(0)
  const [storyCount, setStoryCount] = useState(0)
  const [selectedTag, setSelectedTag] = useState(storyType)

  // Webstory API
  useEffect(() => {
    // get data from makestories api - only fetching based on the tags set as 'storyType'
    fetch(
      `https://api.v2.makestories.io/channel/-NMXf2LrCrDUCRPjZ-wJ/stories?tags=${storyType}&per_page=0`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setting the stories into a state
        setAllWebStories(resultData.stories)
        setWebStories(resultData.stories)
        // get the total story count
        setStoryCount(resultData.stories.length)
        setAllStoryCount(resultData.stories.length)
        // get all other tags associated with these stories, will be used for Filter.
        const getTags = resultData.stories.map((val: any) => {
          // filter out the main tag - need to check this
          return val.tags.filter(
            (e: string) => e !== storyType && !e.includes('#')
          )
        })
        // remove the repeating tags, storing it as a state
        setDisplayTags([...new Set([].concat(...getTags))])
      }) // set data
  }, [storyType])

  // filter items based on tag
  const filterItems = (tagName: React.SetStateAction<string>) => {
    const updatedStories = allWebStories.filter(
      (item: { tags: React.SetStateAction<string>[] }) =>
        item.tags.includes(tagName)
    )
    // storing the new stories as webstory state
    setWebStories(updatedStories)
    // active tag
    setSelectedTag(tagName)
    // get total count for the updated stories
    setStoryCount(updatedStories.length)
  }

  // story items
  const storyItems = webStories?.map((b: any) => {
    return (
      <StoryList
        key={b.url}
        title={b.title}
        url={b.url}
        feature_image={b.posters.landscape}
        publishing_date={new Date(b.last_update).toLocaleDateString()}
      />
    )
  })

  // story schema
  const storyItemsSchema = JSON.stringify(
    generateBlogItemsSchema({
      blogPrefix: prefix,
      blogItems: webStories,
    })
  )

  return (
    <div id="webStories">
      <Container>
        <Row>
          <Col>
            <div className="storyHeader">
              <h1 className="title">{title}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          {displayFilters && (
            <div className="col-12 display-pills">
              <button
                type="button"
                className={`tag-pill ${
                  selectedTag === storyType && 'selected'
                }`}
                onClick={() => {
                  setWebStories(allWebStories)
                  setSelectedTag(storyType)
                  setStoryCount(allStoryCount)
                }}
              >
                All
              </button>
              {displayTags.map((s: any) => {
                return (
                  <button
                    type="button"
                    className={`tag-pill ${selectedTag === s && 'selected'}`}
                    key={s}
                    onClick={() => {
                      filterItems(s)
                    }}
                  >
                    {s}
                  </button>
                )
              })}
            </div>
          )}
          <Col>
            <div className="blogs-count">
              {`${storyCount} ${
                storyCount > 1 ? 'Stories' : 'Story'
              } found in this Guide`}
            </div>
            <Helmet>
              <script type="application/ld+json">{storyItemsSchema}</script>
            </Helmet>
            <div className="story-list">{storyItems}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Stories
